<template>
  <div class="top">

    <div class="row justify-content-center">
      <div class="col-4 m-4 justify-content-center text-center">
        <h3 class="title">Random Photo</h3><img class="reload" v-on:click="shakeImage()"/>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="image-content col-8 justify-content-center text-center">
        <a v-if="this.gameComplete" :href="getImageUrl()">
          <img class="img-thumbnail" :src="getCropUrl()">
        </a>
      </div>
    </div>

    <div v-if="!this.gameComplete" class="row justify-content-center">
      <div class="col-1">
        <button class="btn btn-warning" v-on:click="decPuzzleSize">-</button>
      </div>

      <div class="image-content col-8 justify-content-center text-center">
        <canvas id="puzzle_canvas"/>
      </div>
      <div class="col-1">
        <button class="btn btn-danger" v-on:click="incPuzzleSize">+</button>
      </div>
    </div>
    <vue3-snackbar bottom right :duration="4000"></vue3-snackbar>
  </div>
</template>

<script>
import axios from "axios"
import Puzzle from "../../public/js/puzzle";

export default {
  name: 'IndexView',
  props: {
    msg: String
  },
  data() {
    return {
      image: undefined,
      gameComplete: false,
      puzzle: new Puzzle(3, this.puzzleComplete)
    }
  },
  methods: {
    incPuzzleSize() {
      this.puzzle.incPuzzleSize();
    },
    decPuzzleSize() {
      this.puzzle.decPuzzleSize();
    },
    puzzleComplete(status) {
      console.log("game complete : ", status);
      this.gameComplete = status;
      this.$snackbar.add({
        type: 'success',
        text: 'game complete'
      })
    },
    restoreImage() {
      this.puzzle.restoreOrgImage();
    },
    shakeImage() {
      this.puzzle.shakeImage();
    },
    getImageUrl() {
      return 'https://pm.iwas.co.kr/image/view/' + this.image.hash;
    },
    getCropUrl() {
      return `https://pm.iwas.co.kr/image/crop/${this.image.hash}/${this.image.x}/${this.image.y}/${this.image.w}/${this.image.h}`;
    },
    getOrgCropUrl() {
      return `https://svc.iwas.co.kr/image/crop/${this.image.hash}/${this.image.x}/${this.image.y}/${this.image.w}/${this.image.h}`;
    },
    initGame() {
      axios.get('https://pm.iwas.co.kr/api/pt/image/random')
          .then((res) => {
            console.log(res.data);
            this.image = res.data;

            this.puzzle.init('puzzle_canvas', 500, 500)
            this.puzzle.loadImage(this.getOrgCropUrl())
          });
    }
  },
  mounted() {
    this.initGame()
  }
}
</script>

<style scoped>
.title {
}

.top {
  height: 90%;
  width: 90%;
  margin-left: auto;
  position: absolute;
  top: 10%;
}

.img-thumbnail {
  max-width: 300px;
}

.reload {
  background-image: url("/public/img/reload.png");
  width: 32px;
  height: 32px;
  background-size: contain;
  border: 0px;
}
</style>

