<template>
<div class="row justify-content-center text-center m-5">
  <div class="col-3">
  <button class="btn btn-danger" v-on:click="onNotAdult()">미성년(나가기)</button>
  </div>
    <div class="col-3">
  <button class="btn btn-primary" v-on:click="onAdult()">성인</button>
  </div>
</div>
</template>

<script>
export default {
  name: "GuardView",
  methods : {
    onNotAdult() {
      window.history.back();
    },
    onAdult() {
      this.$router.push("/content");
    }
  }
}
</script>

<style scoped>

</style>