import { createApp } from 'vue'
import App from './App.vue'
import IndexView from "@/components/IndexView"
import { createWebHistory, createRouter } from "vue-router";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { BootstrapVue3 } from 'bootstrap-vue-3'
import GuardView from "@/components/GuardView";
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/dist/style.css";

const routes = [
    {
        path: "/",
        name: "Guard",
        component: GuardView,
    },
    {
        path: "/content",
        name: "Index",
        component: IndexView,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App);
app.use(router);
app.use(SnackbarService);
app.component("vue3-snackbar", Vue3Snackbar);
app.use(BootstrapVue3)
app.mount('#app')
