const Puzzle = function (puzzleSize,complete) {
    let PUZZLE_SIZE = puzzleSize;
    let _canvas;
    let _context;
    let _image;
    let _info = {};
    let _orgImageData = null;
    let _partImageMap;
    let _complete = complete || function(status) { console.log("default : ",status);}
    const _obj = {}

    _obj.incPuzzleSize = function (){
        PUZZLE_SIZE +=1;
        PUZZLE_SIZE = Math.min(100,PUZZLE_SIZE);
        _obj.initGame(_image);
    }
    _obj.decPuzzleSize = function (){
        PUZZLE_SIZE -=1;
        PUZZLE_SIZE = Math.max(2,PUZZLE_SIZE);
        _obj.initGame(_image);
    }

    function getRandom(max) {
        return Math.floor(Math.random() * (max))
    }

    function isValidIndex(index) {
        if (index.x < 0 || index.y < 0)
            return false;
        if (index.x >= PUZZLE_SIZE || index.y >= PUZZLE_SIZE)
            return false;
        return true;
    }

    function getTopIndex(index) {
        return {y: index.y - 1, x: index.x}
    }

    function getBottomIndex(index) {
        return {y: index.y + 1, x: index.x}
    }

    function getLeftIndex(index) {
        return {y: index.y, x: index.x - 1}
    }

    function getRightIndex(index) {
        return {y: index.y, x: index.x + 1}
    }

    function isBlankIndex(index) {
        if (!isValidIndex(index))
            return false;

        if (_partImageMap[index.y][index.x].data == null)
            return true;
        return false;
    }

    function getRandomIndex() {
        let y1 = getRandom(PUZZLE_SIZE);
        let x1 = getRandom(PUZZLE_SIZE);
        return {y: y1, x: x1}
    }

    function swap(index1, index2) {
        console.log("swap : ", index1, index2);
        let temp = _partImageMap[index1.y][index1.x];
        _partImageMap[index1.y][index1.x] = _partImageMap[index2.y][index2.x];
        _partImageMap[index2.y][index2.x] = temp;
    }

    function checkMovableAndMove(index) {
        for (let y = 0; y < PUZZLE_SIZE; y++) {
            for (let x = 0; x < PUZZLE_SIZE; x++) {
                if (isBlankIndex(getTopIndex(index))) {
                    swap(index, getTopIndex(index))
                } else if (isBlankIndex(getLeftIndex(index))) {
                    swap(index, getLeftIndex(index));
                } else if (isBlankIndex(getRightIndex(index))) {
                    swap(index, getRightIndex(index));
                } else if (isBlankIndex(getBottomIndex(index))) {
                    swap(index, getBottomIndex(index));
                }
                return;
            }
        }
    }

    function onMouseDown(evt) {
        let rect = _canvas.getBoundingClientRect();
        let pt = {
            x: (evt.clientX - rect.left) * (_canvas.width / rect.width),
            y: (evt.clientY - rect.top) * (_canvas.height / rect.height)
        };
        let index = {
            y: Math.floor(pt.y / (_canvas.height / PUZZLE_SIZE)),
            x: Math.floor(pt.x / (_canvas.width / PUZZLE_SIZE))
        };
        // console.log("down index : ", index);
        checkMovableAndMove(index);
        _obj.redrawPartImage();
        checkComplete();

    }
    function checkComplete() {
        for (let y = 0; y < PUZZLE_SIZE; y++) {
            for (let x = 0; x < PUZZLE_SIZE; x++) {
                let partIndex = _partImageMap[y][x].index;
                if (partIndex.y !== y || partIndex.x !== x) {
                    return;
                }
            }
        }
        _complete(true);
    }

    _obj.init = function (canvasId, width, height) {
        _canvas = document.getElementById(canvasId);
        _context = _canvas.getContext("2d");
        if (width)
            _canvas.width = width;
        if (height)
            _canvas.height = height;
        _canvas.addEventListener("mousedown", onMouseDown, false);
    }
    _obj.restoreOrgImage = function () {
        _context.putImageData(_orgImageData, 0, 0);
    }

    _obj.redrawPartImage = function () {
        _context.clearRect(0, 0, _canvas.width, _canvas.height);
        const w = _canvas.width / PUZZLE_SIZE;
        const h = _canvas.height / PUZZLE_SIZE;
        for (let y = 0; y < PUZZLE_SIZE; y++) {
            for (let x = 0; x < PUZZLE_SIZE; x++) {
                let sx = x * w;
                let sy = y * h;
                // console.log(`put image : [${y}][${x}]`, sx, sy);
                if (_partImageMap[y][x].data !== null) _context.putImageData(_partImageMap[y][x].data, sx, sy, 0, 0, w - 2, h - 2);
            }
        }
    }

    _obj.shakeImage = function () {
        for (let cnt = 0; cnt < 1000; cnt++) {
            let randIndex = getRandomIndex(PUZZLE_SIZE);
            checkMovableAndMove(randIndex);
        }
        _context.clearRect(0, 0, _canvas.width, _canvas.height);
        _obj.redrawPartImage();
    }
    _obj.loadImage = function (url) {
        _image = new Image();
        console.log('load image :', url);
        _image.onload = function () {
            console.log("load complete");
            _info.image = {w: this.width, h: this.height};
            if (this.width < _canvas.width) {
                // _canvas.width = this.width;
            }
            _info.ratio = _canvas.width / this.width;
            _canvas.height = this.height * _info.ratio;
            _info.canvas = {w: _canvas.width, h: _canvas.height};
            _obj.initGame(this);
        };
        _image.src = url;
        _image.crossOrigin = "Anonymous";
    }
    _obj.initGame = function (image) {
        _context.drawImage(image, 0, 0, _canvas.width, _canvas.height);
        _orgImageData = _context.getImageData(0, 0, _canvas.width, _canvas.height);
        const partWidth = _canvas.width / PUZZLE_SIZE;
        const partHeight = _canvas.height / PUZZLE_SIZE;
        let w = partWidth;
        let h = partHeight;

        _partImageMap = new Array(PUZZLE_SIZE);
        for (let i = 0; i < PUZZLE_SIZE; i++) {
            _partImageMap[i] = new Array(PUZZLE_SIZE);
        }
        console.log("canvas size = ", _canvas.width, _canvas.height);
        for (let y = 0; y < PUZZLE_SIZE; y++) {
            for (let x = 0; x < PUZZLE_SIZE; x++) {

                let sx = x * w;
                let sy = y * h;
                // console.log(`crop info : [${y}][${x}]`, sx, sy, w, h);
                _partImageMap[y][x] = {
                    index: {y: y, x: x},
                    data: _context.getImageData(sx, sy, w, h),
                    dim: {x: sx, y: sy, w: w, h: h}
                };
            }
        }
        _partImageMap[PUZZLE_SIZE - 1][PUZZLE_SIZE - 1].data = null;
        _obj.shakeImage();

    }
    return _obj;
};

export default Puzzle